import { version } from './version';

export const environment = {
  BASE_URL: 'https://dte.hmg.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '2',
  schema: 'pbi_dte_homol',
  groupId: '0078aca5-87f8-4c0b-a5d0-d8e0852b4090',
  analiseGeral: 'b47a476d-6494-4516-be5c-82f22a6c9800',
  analiseUsuarios: '',
  analiseComunicados: '138b912b-30af-4aff-b993-73eaba0c04cc',
  analiseProcurador: '',
  analiseEventos: 'aa7cd387-0b4d-44e6-be79-2b108c623c02',
  analiseContribuintes: 'afd6ac57-1695-4e1f-a439-d5d5a68f86b7'
};
